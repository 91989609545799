@import "fonts.css";
@import '~bootstrap/dist/css/bootstrap.min.css';

:root {
    --color-primary: #001c54;
    --color-dark: #000000;
    --color-light: #ffffff;
    --color-gradient-1: #0d4dff;
    --color-gradient-2: #00ffa9;
    --color-gradient-middle: #07a6d4;
    --color-gradient: linear-gradient(127deg, var(--color-gradient-1) 0%, var(--color-gradient-2) 100%);
    --color-gradient-middle-right: linear-gradient(127deg, var(--color-gradient-middle) 0%, var(--color-gradient-2) 100%);
    --color-gradient-middle-left: linear-gradient(127deg, var(--color-gradient-2) 0%, var(--color-gradient-middle) 100%);
}

.debug {
    outline: 1px solid red;
}

* {
    font-family: "Gilroy", sans-serif;
}

*::selection {
    background-color: transparent;
    color: inherit;
}

#simulator .result > span::selection, #simulator input::selection, label::selection, #form-contact input::selection, #form-contact textarea::selection, #form-contact select::selection, .roadmap-card .title .date::selection, .list-round li::selection, .roadmap-card .content ul li::selection, h1::selection, h2::selection, h3::selection, h4::selection, h5::selection, h6::selection, p::selection, input::selection, .selectable::selection {
    background-color: var(--color-primary);
    color: var(--color-light);
}

html, body {
    overflow-x: hidden;
    font-size: 12px;
}

body {
    scroll-behavior: smooth;
}

section {
    margin-top: 1.875em;
    margin-bottom: 1.875em;
}

h1 {
    font-size: 5em;
    font-family: "Gilroy ExtraBold", sans-serif !important;
    text-transform: uppercase;
    color: var(--color-primary);
}

h3 {
    font-size: 1.5em;
    font-weight: bold;
    text-transform: uppercase;
}

p {
    font-size: 1.375em;
}

img {
    image-rendering: -webkit-optimize-contrast;
}

.btn {
    background-color: var(--color-primary);
    padding: 0.9375em 1.875em;
    color: var(--color-light);
    border: none;
    border-radius: 0;
    font-size: 1.125em;
    text-transform: uppercase;
    font-weight: bold;
    transition: background-color 250ms ease-in-out;
    position: relative;
    z-index: 0;
}

.btn::before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background: var(--color-gradient);
    z-index: -1;
    opacity: 0;
    transition: all 250ms ease-in-out;
}

.btn:hover {
    color: var(--color-light);
}

.btn:hover::before {
    opacity: 1;
}

.list-round, .roadmap-card .content ul {
    font-size: 14px;
    list-style: none;
}

.list-round li, .roadmap-card .content ul li {
    font-size: 1.125em;
    position: relative;
    margin-top: 0.3125em;
    margin-bottom: 0.3125em;
}

.list-round li::before, .roadmap-card .content ul li::before {
    content: "";
    position: absolute;
    width: 0.9375em;
    height: 0.9375em;
    border-radius: 50%;
    background-color: var(--color-primary);
    left: -1.25em;
    top: 0.25em;
}

.spaced {
    margin: 1.875em;
}

.spaced-small {
    margin: 0.9375em;
}

.spaced-big {
    margin: 3.75em;
}

.space-x {
    margin-left: 1.875em;
    margin-right: 1.875em;
}

.space-x-small {
    margin-left: 0.9375em;
    margin-right: 0.9375em;
}

.space-x-big {
    margin-left: 3.75em;
    margin-right: 3.75em;
}

@media screen and (max-width: 991px) {
    .space-x-big {
        margin-left: 1.875em;
        margin-right: 1.875em;
    }
}

.space-y {
    margin-top: 1.875em;
    margin-bottom: 1.875em;
}

.space-y-small {
    margin-top: 0.9375em;
    margin-bottom: 0.9375em;
}

.space-y-big {
    margin-top: 3.75em;
    margin-bottom: 3.75em;
}

.sp-t {
    margin-top: 0.9375em;
}

.sp-t-1 {
    margin-top: 0.625em;
}

.sp-t-2 {
    margin-top: 1.25em;
}

.sp-t-3 {
    margin-top: 1.875em;
}

.sp-t-4 {
    margin-top: 2.5em;
}

.sp-t-5 {
    margin-top: 3.125em;
}

.sp-t-6 {
    margin-top: 3.75em;
}

.sp-b {
    margin-bottom: 0.9375em;
}

.sp-b-1 {
    margin-bottom: 0.625em;
}

.sp-b-2 {
    margin-bottom: 1.25em;
}

.sp-b-3 {
    margin-bottom: 1.875em;
}

.sp-b-4 {
    margin-bottom: 2.5em;
}

.sp-b-5 {
    margin-bottom: 3.125em;
}

.sp-b-6 {
    margin-bottom: 3.75em;
}

.sp-l {
    margin-left: 0.9375em;
}

.sp-l-1 {
    margin-left: 0.625em;
}

.sp-l-2 {
    margin-left: 1.25em;
}

.sp-l-3 {
    margin-left: 1.875em;
}

.sp-l-4 {
    margin-left: 2.5em;
}

.sp-l-5 {
    margin-left: 3.125em;
}

.sp-l-6 {
    margin-left: 3.75em;
}

.sp-r {
    margin-right: 0.9375em;
}

.sp-r-1 {
    margin-right: 0.625em;
}

.sp-r-2 {
    margin-right: 1.25em;
}

.sp-r-3 {
    margin-right: 1.875em;
}

.sp-r-4 {
    margin-right: 2.5em;
}

.sp-r-5 {
    margin-right: 3.125em;
}

.sp-r-6 {
    margin-right: 3.75em;
}

nav.navbar {
    padding: 0;
    background-color: var(--color-light) !important;
}

nav.navbar .container {
    height: 100%;
}

nav.navbar .navbar-brand {
    height: 7.5em;
    padding: 0;
}

nav.navbar .navbar-brand #brand-container .mask-container-right {
    height: calc(100% - 0.625em);
    width: calc(100% - 5em);
    z-index: 0;
}

nav.navbar .navbar-brand #brand-container .mask-container-right::before {
    content: "";
    position: absolute;
    height: 0.625em;
    width: 50vw;
    background-color: #0b69f1;
    bottom: -0.625em;
    right: 100%;
}

nav.navbar .navbar-brand #brand-container #brand-box {
    position: relative;
    margin-right: 1em;
    display: flex;
    align-items: center;
    height: 6.25em;
}

nav.navbar .navbar-brand #brand-container #brand-box img {
    height: clamp(5.625em, 20vw, 6.875em);
}

@media screen and (max-width: 768px) {
    nav.navbar .navbar-brand #brand-container #brand-box img {
        height: 3.75em;
        margin-top: 0.9375em;
    }
}

@media screen and (max-width: 991px) {
    nav.navbar ul {
        padding: 1.875em;
    }
}

nav.navbar li {
    font-size: 1.375em;
    text-transform: uppercase;
}

nav.navbar li a.nav-link {
    padding: 0.25em 0.75em !important;
    background: var(--color-gradient);
    margin: 0.25em;
    font-weight: bold;
    color: var(--color-light) !important;
}

nav.navbar li a.nav-link:not(.active) {
    position: relative;
    z-index: 1;
    font-weight: 400;
    color: var(--color-dark) !important;
}

nav.navbar li a.nav-link:not(.active)::before {
    content: "";
    background: var(--color-light);
    position: absolute;
    --marge: 0em;
    left: var(--marge);
    top: var(--marge);
    right: var(--marge);
    bottom: var(--marge);
    transition: all 250ms ease-in-out;
    z-index: -1;
}

nav.navbar li a.nav-link:not(.active):hover::before {
    --marge: 0.25em;
}

nav.navbar li a.nav-link:not(.active).active:hover, nav.navbar li a.nav-link:not(.active).active:active, nav.navbar li a.nav-link:not(.active).active:focus, nav.navbar li a.nav-link:not(.active).active:visited {
    color: var(--color-light) !important;
}

nav.navbar .navbar-toggler {
    border: none;
    transform: scale(2);
    color: var(--color-primary);
    box-shadow: none !important;
}

.text-light {
    color: var(--color-light);
}

.glass, #simulator input, footer .btn, footer .form-control {
    backdrop-filter: blur(5px) saturate(156%);
    background-color: rgba(255, 255, 255, 0.6);
    border-radius: 12px;
    border: 1px solid rgba(209, 213, 219, 0.3);
}

.gradient {
    background: var(--color-gradient);
    position: relative;
}

.gradient .mask-bottom-right {
    overflow: hidden;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    width: 5em;
}

.gradient .mask-bottom-right::after {
    content: "";
    position: absolute;
    width: 4.375em;
    height: 10em;
    transform: rotate(30deg);
    top: -0.375em;
    left: 2.59375em;
    background-color: var(--color-light);
}

.gradient .mask-bottom-right::before {
    content: "";
    position: absolute;
    width: 3.75em;
    height: 10em;
    transform: rotate(30deg);
    top: -1.71875em;
    left: -1.96875em;
    background-color: var(--color-light);
}

.gradient .mask-container-right {
    position: absolute;
    width: 100%;
    height: calc(100% - 0.625em);
    background-color: var(--color-light);
}

#more {
    font-size: 1em;
    text-transform: uppercase;
    color: var(--color-dark);
    font-weight: 500;
    text-decoration: none;
    margin-left: 1.875em;
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    gap: 0.3125em;
}

.divider {
    height: 5em;
    background: var(--color-gradient);
    position: relative;
}

@media screen and (max-width: 991px) {
    .divider {
        height: 0.625em;
    }
}

.divider::before, .divider::after {
    content: "";
    position: absolute;
    transform: skew(30deg);
    background-color: var(--color-light);
    width: 100vw;
}

@media screen and (max-width: 991px) {
    .divider::before, .divider::after {
        display: none;
    }
}

.divider::before {
    bottom: 0;
    top: 0.625em;
    left: calc(-100vw + var(--pos));
}

.divider::after {
    bottom: 0.625em;
    top: 0;
    left: calc(calc(0vw + var(--pos)) + 0.3125em);
}

.divider.reverse::before, .divider.reverse::after {
    transform: skew(-30deg);
}

.divider.reverse::before {
    bottom: 0.625em;
    top: 0;
}

.divider.reverse::after {
    bottom: 0;
    top: 0.625em;
}

.h2-container {
    padding: 1.875em;
    position: absolute;
    z-index: 2;
    margin-top: 0.3125em;
}

@media screen and (min-width: 992px) {
    .h2-container.h2-right {
        text-align: right;
        right: calc(calc(100vw - var(--pos)) + 0.9375em);
    }
}

@media screen and (max-width: 991px) {
    .h2-container.h2-right {
        position: relative !important;
        text-align: center;
        width: 100%;
    }
}

@media screen and (min-width: 992px) {
    .h2-container.h2-left {
        text-align: left;
        left: calc(var(--pos) + 0.9375em);
    }
}

@media screen and (max-width: 991px) {
    .h2-container.h2-left {
        position: relative !important;
        text-align: center;
        width: 100%;
    }
}

h2 {
    color: var(--color-primary);
    font-size: 2.875em;
    font-weight: bold;
    text-transform: uppercase;
}

.img-box {
    width: 100%;
    background-size: cover !important;
    background-position: 50% !important;
    padding: 0.25em;
    position: relative;
    border: 0.5em solid var(--color-light);
}

.img-box::before {
    content: "";
    position: absolute;
    top: -1.125em;
    bottom: -1.125em;
    right: -1.125em;
    left: -1.125em;
    background: var(--color-gradient);
    z-index: -1;
}

#img-money {
    height: 32em;
    background-size: contain;
    background-position: center;
}

#img-environement {
    height: 32em;
    background-size: contain;
    background-position: center;
}

#ourvalues::before, #ourvalues::after {
    content: "";
    position: absolute;
    background-color: var(--color-light);
    width: 100vw;
    height: 4.375em;
}

@media screen and (max-width: 991px) {
    #ourvalues::before, #ourvalues::after {
        display: none;
    }
}

#ourvalues::before {
    transform: skew(30deg);
    top: 0;
    left: calc(0vw + var(--pos-1));
}

#ourvalues::after {
    transform: skew(-30deg);
    bottom: 0;
    left: calc(calc(0vw + var(--pos-2)) + 0.3125em);
}

#ourvalues .container {
    padding-bottom: 10em;
    padding-top: 10em;
}

@media screen and (max-width: 991px) {
    #ourvalues .container {
        padding-top: 0em;
    }
}

#ourvalues .value-card {
    border-radius: 0;
    padding: 0.9375em;
}

#ourvalues .value-card h3 {
    color: var(--color-primary);
}

#ourvalues .value-card .material-icons-sharp {
    font-size: 3.4375em;
    color: var(--color-primary);
}

@media screen and (min-width: 992px) {
    #environement {
        margin-top: -6.875em;
    }
}

#environement .container {
    padding-top: 7.375em;
}

@media screen and (max-width: 991px) {
    #environement .container {
        padding-top: 0em;
        margin-top: -5.625em;
    }
}

#environement #non-profit {
    margin: auto;
    color: var(--color-light);
    text-align: center;
    text-transform: uppercase;
    padding-top: 5.625em;
    padding-bottom: 5.625em;
    padding-left: 1.875em;
    padding-right: 1.875em;
    max-width: 400px;
}

#environement #non-profit h1 {
    font-size: 1.5625em;
    color: #FFFFFF;
}

#environement #non-profit h1 small {
    font-weight: bolder;
    font-size: 1.5em;
}

#environement #non-profit h1 .fat {
    line-height: 0.8;
    font-weight: bolder;
    display: block;
    font-size: 11.25em;
}

@media screen and (max-width: 991px) {
    #environement #non-profit h1 .fat {
        font-size: 7.5em;
    }
}

footer {
    min-height: 50vh;
    padding-top: 7.5em;
    color: var(--color-light);
    text-align: center;
}

footer::before {
    content: "";
    position: absolute;
    background-color: var(--color-light);
    width: 100vw;
    height: 4.375em;
    transform: skew(-30deg);
    top: 0;
    right: calc(0vw + var(--pos));
}

@media screen and (max-width: 991px) {
    footer::before {
        display: none;
    }
}

footer .logo-container {
    top: 0;
    padding: 2.125em;
    position: absolute;
    right: calc(var(--pos));
}

footer .logo-container .logo {
    position: absolute;
    left: 8em;
    width: clamp(200px, 20vw, 280px);
}

@media screen and (max-width: 991px) {
    footer .logo-container {
        position: relative;
        right: unset;
        text-align: center;
    }

    footer .logo-container .logo {
        position: relative;
        left: unset;
    }
}

@media screen and (max-width: 991px) {
    footer {
        padding-top: 0;
    }
}

footer .people {
    font-size: 1.375em;
}

footer .title {
    font-weight: bold;
    font-size: 2.125em;
    text-transform: uppercase;
}

footer .links {
    list-style-type: none;
    padding: 0;
    margin: 0 0 1.875em;
}

footer .links li {
    margin-top: 0.9375em;
}

footer .links li a {
    font-size: 1.375em;
    color: var(--color-light);
    text-decoration: none;
}

footer .links li a:hover {
    text-decoration: underline;
}

footer .form-control {
    border-radius: 0;
    box-shadow: none;
    font-size: 1.375em;
    transition: background-color 250ms ease-in-out;
}

footer .form-control:active, footer .form-control:focus {
    box-shadow: none;
}

footer .btn {
    border-radius: 0;
    box-shadow: none;
    background-color: rgba(0, 28, 84, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    transition: background-color 250ms ease-in-out;
}

footer .btn:hover {
    background-color: rgba(0, 28, 84, 0.9);
}

footer .btn:hover::before {
    opacity: 0;
}

footer .socials {
    display: flex;
    justify-content: center;
    align-items: center;
}

footer .socials img {
    margin: 0.9375em;
    height: 3.375em;
    fill: var(--color-light) !important;
    transform: scale(1);
    transition: transform 250ms ease-in-out;
}

footer .socials img:hover {
    transform: scale(1.2);
}

.project-card {
    text-align: center;
    height: 400px;
}

.project-card * {
    text-align: center;
}

.project-card img {
    height: 12.5em;
}

.project-card .title {
    font-size: 2.375em;
    font-weight: bold;
}

.project-card .target {
    font-size: 1.125em;
    text-transform: uppercase;
}

.check-card {
    margin-bottom: 0.3125em;
    margin-top: 0.3125em;
}

.check-card span {
    color: var(--color-primary);
    font-size: 2.625em;
}

.check-card p {
    margin-top: 0.1875em;
}

.roadmap-card .title h4 {
    font-size: 2.25em;
    font-weight: bold;
    color: var(--color-primary);
}

.roadmap-card .title .date {
    font-size: 1.375em;
    position: relative;
    top: -0.75em;
}

.roadmap-card .title .objectif {
    color: var(--color-primary);
    font-size: 3em;
}

.roadmap-card .title .objectif > small {
    font-size: 0.4em;
    display: block;
    opacity: 0.8;
}

.roadmap-card:first-child {
    margin-top: 3.75em;
}

.roadmap-card .card {
    position: relative;
    border-radius: 0;
    border: none;
}

.roadmap-card .card::before {
    content: "";
    left: 1.875em;
    top: 0.9375em;
    right: -0.625em;
    height: 0.625em;
    position: absolute;
    background: var(--color-gradient-middle-left);
}

.roadmap-card:nth-child(2n+1) .card::before {
    background: var(--color-gradient-middle-right);
    left: -0.625em;
    right: 1.875em;
}

.roadmap-card:nth-child(2n+1) .row {
    justify-content: flex-end;
}

.roadmap-card:nth-child(2n+1) .row .title {
    text-align: right;
}

.roadmap-card .list-round li::before, .roadmap-card .content ul li::before {
    top: 0.5625em;
    left: -1.5625em;
}

#roadmap-container {
    position: relative;
}

#roadmap-container::before {
    content: "";
    width: 0.625em;
    background-color: var(--color-gradient-middle);
    top: -8.125em;
    bottom: -8.1875em;
    z-index: 5;
    position: absolute;
}

@media screen and (max-width: 991px) {
    #roadmap-container::before {
        display: none;
    }

    #roadmap-container .card::before {
        background: var(--color-gradient);
        left: 0.625em;
        right: 0.625em;
        top: 0;
    }

    #roadmap-container .card .title {
        text-align: center;
    }
}

#form-contact input, #form-contact textarea, #form-contact select {
    border-radius: 0;
    border: 2px solid var(--color-primary);
    padding: 0.9375em;
}

#form-contact textarea {
    height: 11.25em;
    min-height: 3.75em;
}

#form-contact .form-group {
    padding: 0.9375em;
}

label {
    font-size: 1.125em;
    text-transform: uppercase;
}

.img-contact {
    height: 4.6875em;
    padding: 0.46875em;
}

#investors ul li {
    font-size: 1.25em;
    margin-top: 0.9375em;
}

#simulator {
    color: var(--color-light);
}

#simulator input {
    margin-top: 0.625em;
    border-radius: 0;
    padding: 0.9375em;
}

#simulator .spaced > *:not(:last-child) {
    margin-bottom: 0.9375em;
}

#simulator .result {
    width: 100%;
    display: inline-flex;
    justify-content: space-between;
}

#simulator .result .amount {
    text-align: right;
}

@media screen and (min-width: 992px) {
    #sticky-simulator {
        position: sticky;
        width: 100%;
        margin-top: 3.75em;
        top: 0;
        bottom: 800px;
        margin-bottom: 102.1875em;
        justify-content: center;
        display: flex;
        z-index: 0;
    }

    #sticky-simulator > .container {
        position: absolute;
        max-height: 100vh;
    }

    #sticky-simulator > .container .col-12:not(.gradient) > .spaced > p {
        font-size: 1.1em;
        text-align: center;
        opacity: 0.8;
    }

    #sticky-simulator > .container h2, #sticky-simulator > .container p, #sticky-simulator > .container input, #sticky-simulator > .container span, #sticky-simulator > .container textarea, #sticky-simulator > .container select, #sticky-simulator > .container button, #sticky-simulator > .container a {
        z-index: 10;
    }

    #investors {
        margin-top: -102.1875em;
    }
}

#investors-customer, #investors, #invest {
    position: relative;
    pointer-events: none;
}

#investors-customer h2, #investors-customer h3, #investors-customer h4, #investors-customer li, #investors-customer p, #investors-customer input, #investors-customer span, #investors-customer textarea, #investors-customer select, #investors-customer button, #investors-customer a, #investors h2, #investors h3, #investors h4, #investors li, #investors p, #investors input, #investors span, #investors textarea, #investors select, #investors button, #investors a, #invest h2, #invest h3, #invest h4, #invest li, #invest p, #invest input, #invest span, #invest textarea, #invest select, #invest button, #invest a {
    z-index: 10;
    pointer-events: all;
}

.img-wealth {
    width: 100%;
    background-size: contain !important;
    background-position: 50% !important;
    background-repeat: no-repeat !important;
    height: 75vh !important;
    padding: 0.25em;
    position: relative;
    border: 0.5em solid var(--color-light);
}

.highlight {
    background: var(--color-gradient);
    color: var(--color-light);
    font-weight: bold;
    padding: 0.625em;
    font-size: 1.5em;
    text-align: center;
    text-transform: uppercase;
}

.img-gradient-box {
    margin: 0.625em;
    padding: 0.625em;
    color: var(--color-primary);
}

.img-gradient-box > img {
    background-color: var(--color-light);
}

.img-gradient-box h3, .img-gradient-box p {
    opacity: 0.9;
    text-align: center !important;
}

.flag {
    border: none;
    background-color: transparent;
}

.flag-english {
    width: 30px;
    height: 30px;
    background-image: url("../../assets/images/logos/english.svg");
    background-size: contain;
    background-repeat: no-repeat;
}

.flag-french {
    width: 30px;
    height: 30px;
    background-image: url("../../assets/images/logos/french.svg");
    background-size: contain;
    background-repeat: no-repeat;
}

.graphic-img {
    width: 400px;
    height: 350px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    image-rendering: optimizeQuality;
}

.graphic-2 {
    width: 100% !important;
}

.flag-btn {
    border: 0;
    background-color: transparent;
}

.text-primary {
    color: var(--bs-body-color) !important;
}

.mt-10 {
    margin-top: 6.5rem;
}

.scroll-to-top {
    background-color: white;
    right: 40px;
    bottom: 40px;
    position: fixed;
    z-index: 2;
    cursor: pointer;
    border-radius: 7px;
    width: 40px;
    height: 40px;
    transition: opacity 1s ease-in-out;
    box-shadow: 0 9px 25px 0 rgba(132, 128, 177, 0.28);
    border: none;
    outline: none;
}

.scroll-to-top:active {
    transform: matrix(0.95, 0, 0, 0.95, 0, 0);
}

.swal2-confirm {
    background: var(--color-gradient) !important;
}

@media screen and (max-width: 400px) {
    .wealth {
        font-size: 46px;
    }

    #environement #non-profit h1 small {
        font-size: .8em;
    }
}