@font-face {
    font-family: 'Gilroy';
    src: url('../fonts/Gilroy-Bold.eot');
    src: url('../fonts/Gilroy-Bold.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Gilroy-Bold.woff2') format('woff2'),
    url('../fonts/Gilroy-Bold.woff') format('woff'),
    url('../fonts/Gilroy-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gilroy';
    src: url('../fonts/Gilroy-Black.eot');
    src: url('../fonts/Gilroy-Black.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Gilroy-Black.woff2') format('woff2'),
    url('../fonts/Gilroy-Black.woff') format('woff'),
    url('../fonts/Gilroy-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gilroy';
    src: url('../fonts/Gilroy-BlackItalic.eot');
    src: url('../fonts/Gilroy-BlackItalic.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Gilroy-BlackItalic.woff2') format('woff2'),
    url('../fonts/Gilroy-BlackItalic.woff') format('woff'),
    url('../fonts/Gilroy-BlackItalic.ttf') format('truetype');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Gilroy';
    src: url('../fonts/Gilroy-ExtraBold.eot');
    src: url('../fonts/Gilroy-ExtraBold.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Gilroy-ExtraBold.woff2') format('woff2'),
    url('../fonts/Gilroy-ExtraBold.woff') format('woff'),
    url('../fonts/Gilroy-ExtraBold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gilroy';
    src: url('../fonts/Gilroy-Bold_1.eot');
    src: url('../fonts/Gilroy-Bold_1.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Gilroy-Bold_1.woff2') format('woff2'),
    url('../fonts/Gilroy-Bold_1.woff') format('woff'),
    url('../fonts/Gilroy-Bold_1.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gilroy';
    src: url('../fonts/Gilroy-Heavy.eot');
    src: url('../fonts/Gilroy-Heavy.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Gilroy-Heavy.woff2') format('woff2'),
    url('../fonts/Gilroy-Heavy.woff') format('woff'),
    url('../fonts/Gilroy-Heavy.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gilroy';
    src: url('../fonts/Gilroy-Medium.eot');
    src: url('../fonts/Gilroy-Medium.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Gilroy-Medium.woff2') format('woff2'),
    url('../fonts/Gilroy-Medium.woff') format('woff'),
    url('../fonts/Gilroy-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gilroy';
    src: url('../fonts/Gilroy-Light.eot');
    src: url('../fonts/Gilroy-Light.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Gilroy-Light.woff2') format('woff2'),
    url('../fonts/Gilroy-Light.woff') format('woff'),
    url('../fonts/Gilroy-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gilroy';
    src: url('../fonts/Gilroy-Light_1.eot');
    src: url('../fonts/Gilroy-Light_1.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Gilroy-Light_1.woff2') format('woff2'),
    url('../fonts/Gilroy-Light_1.woff') format('woff'),
    url('../fonts/Gilroy-Light_1.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gilroy';
    src: url('../fonts/Gilroy-Regular.eot');
    src: url('../fonts/Gilroy-Regular.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Gilroy-Regular.woff2') format('woff2'),
    url('../fonts/Gilroy-Regular.woff') format('woff'),
    url('../fonts/Gilroy-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

